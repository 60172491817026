import React, { useCallback, useEffect, useState } from "react";
import { SDK_API_SERVER, API_HOST } from "../../constant";
import { detectConcordiumProvider } from "@concordium/browser-wallet-api-helpers";
import { useSDK } from "@metamask/sdk-react";
import axios from "axios";
import {
  getHPackProvider,
  getHashpackWallet,
  disconnectHashpack,
} from "../../hashpack";
import detectEthereumProvider from "@metamask/detect-provider";
import CustomSnackbar from "../../commonComponent/CustomSnackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import Storage from "../../helperFunctions/Storage";
import qs from "qs";

const urbitLogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="44"
    viewBox="0 0 47 44"
    fill="none"
  >
    <path
      d="M26.5703 32.4536C26.5703 31.4136 25.7272 30.5705 24.6872 30.5705H22.2935C21.2535 30.5705 20.4104 31.4136 20.4104 32.4536L20.4104 41.3707C20.4104 42.4107 21.2535 43.2538 22.2935 43.2538L24.6872 43.2538C25.7272 43.2538 26.5703 42.4107 26.5703 41.3707V32.4536Z"
      fill="black"
    />
    <path
      d="M14.8167 40.4577L10.5955 37.9857C9.66053 37.4382 9.37933 36.2166 9.98081 35.3154L15.949 26.3733C16.2986 25.8496 16.8868 25.5352 17.5165 25.5356L29.5185 25.5436C30.1467 25.5441 30.7334 25.8578 31.0826 26.38L37.0549 35.3118C37.6583 36.2142 37.3759 37.4384 36.4382 37.9852L32.2177 40.4464C31.2934 40.9853 30.1065 40.647 29.6053 39.7017L25.3208 31.6201C24.9942 31.0042 24.3542 30.6191 23.657 30.6191L23.3749 30.6191C22.6769 30.6191 22.0363 31.0051 21.7101 31.6221L17.4331 39.7128C16.932 40.6606 15.7419 40.9995 14.8167 40.4577Z"
      fill="black"
    />
    <path
      d="M32.7607 12.203C31.8935 12.7771 31.6561 13.9456 32.2303 14.8127L33.5518 16.8085C34.126 17.6756 35.2945 17.913 36.1616 17.3388L43.5964 12.4156C44.4635 11.8414 44.701 10.673 44.1268 9.80586L42.8052 7.81009C42.231 6.94297 41.0626 6.70551 40.1955 7.2797L32.7607 12.203Z"
      fill="black"
    />
    <path
      d="M45.939 17.6068L46.2085 22.4912C46.2682 23.573 45.4049 24.482 44.3214 24.478L33.5707 24.4389C32.941 24.4366 32.3541 24.1197 32.0068 23.5945L25.3871 13.5832C25.0405 13.0592 24.9782 12.3968 25.2208 11.8173L29.3705 1.90638C29.7897 0.905121 30.9664 0.464687 31.94 0.944606L36.3223 3.1047C37.2819 3.57772 37.6551 4.75421 37.1437 5.69392L32.7711 13.7282C32.4378 14.3405 32.4701 15.0868 32.855 15.6681L33.0108 15.9033C33.3961 16.4852 34.0717 16.8063 34.7662 16.7376L43.8734 15.8366C44.9403 15.7311 45.8799 16.5363 45.939 17.6068Z"
      fill="black"
    />
    <path
      d="M14.2398 12.203C15.1069 12.7771 15.3444 13.9456 14.7702 14.8127L13.4486 16.8085C12.8744 17.6756 11.706 17.913 10.8389 17.3388L3.40408 12.4156C2.53696 11.8414 2.2995 10.673 2.87369 9.80586L4.19527 7.81009C4.76947 6.94297 5.93789 6.70551 6.805 7.2797L14.2398 12.203Z"
      fill="black"
    />
    <path
      d="M1.0615 17.6068L0.791982 22.4912C0.732287 23.573 1.59559 24.482 2.67906 24.478L13.4298 24.4389C14.0595 24.4366 14.6464 24.1197 14.9937 23.5945L21.6134 13.5832C21.9599 13.0592 22.0223 12.3968 21.7796 11.8173L17.63 1.90638C17.2108 0.905121 16.0341 0.464687 15.0605 0.944606L10.6782 3.1047C9.71858 3.57772 9.34534 4.75421 9.85678 5.69392L14.2294 13.7282C14.5627 14.3405 14.5304 15.0868 14.1455 15.6681L13.9897 15.9033C13.6044 16.4852 12.9288 16.8063 12.2343 16.7376L3.12711 15.8366C2.06019 15.7311 1.12057 16.5363 1.0615 17.6068Z"
      fill="black"
    />
    <path
      d="M27.5098 20.2518C27.5098 22.4662 25.7147 24.2613 23.5003 24.2613C21.2859 24.2613 19.4908 22.4662 19.4908 20.2518C19.4908 18.0374 21.2859 16.2423 23.5003 16.2423C25.7147 16.2423 27.5098 18.0374 27.5098 20.2518Z"
      fill="black"
    />
  </svg>
);

const connectConcordiumWallet = async () => {
  const _provider = await detectConcordiumProvider();
  const _genesisHash = await _provider.getSelectedChain();
  let _account = await _provider.connect();
  _account = await _provider.getMostRecentlySelectedAccount();
  if (_account) {
    return {
      provider: _provider,
      account: _account,
      genesisHash: _genesisHash,
    };
  } else {
    return null;
  }
};

//---------------
const getPhantomProvider = () => {
  if ("phantom" in window) {
    const provider: any = (window as any)?.phantom?.solana;
    console.log(provider);
    if (provider?.isPhantom) return provider;
  }
  window.open("https://phantom.app/", "_blank");
  return null;
};
const connectedWallet_T = {
  walletAddress: "",
  provider: "",
  extenstion: "",
  random: -1,
};

type ItemType = any[];

const Web3Wallet = () => {
  const [w3domain, setW3domain] = useState("");
  const [w3domains, setW3domains] = useState([]);
  const [connectedWallet, setConnectedWallet] = useState(connectedWallet_T);
  const [connectedCrypto, setConnectedCrypto] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [isClickDisabled, setClickDisabled] = useState(false);
  const [domains, setDomains] = useState([]);
  const [noDomain, setNodomain] = useState(false);

  const { sdk, connected, connecting, provider, chainId } = useSDK();

  const navigate = useNavigate();

  useEffect(() => {
    // Set the first domain as the default value when w3domains1 is loaded or changed
    if (domains && domains.length > 0) {
      const firstDomain = domains[0];
      setW3domain(firstDomain);
    }
  }, [domains]);

  useEffect(() => {
    Storage.set("currentAddress", currentAddress);
  }, [currentAddress]);

  const __phantom = async (e: any) => {
    console.log("phantom");

    try {
      console.log("innsidetry phantom");

      setConnectedWallet(connectedWallet_T);
      setConnectedCrypto("");
      setCurrentAddress("");
      console.log("connectedWallet==>", connectedWallet);
      setConnectedWallet(connectedWallet_T);
      const provider: any = getPhantomProvider();
      if (provider != null) {
        const resp = await provider?.connect();
        connectedWallet_T.provider = "SOL";
        connectedWallet_T.extenstion = "Phantom";
        connectedWallet_T.walletAddress = resp.publicKey.toString();
        connectedWallet_T.random = Math.random();
        setConnectedWallet(connectedWallet_T);
        setCurrentAddress(connectedWallet_T.walletAddress);
        setConnectedCrypto("SOL");
        console.log(resp.publicKey.toString());
        //const _iresponse=await axios.post(SDK_API_SERVER+"/api/w3/sol/get-domain",{address:connectedWallet_T.walletAddress});
        const _iresponse = await axios.post(
          SDK_API_SERVER + "/api/w3/sol/get-domain",
          { address: currentAddress }
        );
        console.log("_iresponse==>", _iresponse);

        if (_iresponse != null && _iresponse.status != undefined) {
          const _idata = _iresponse.data;
          if (
            _idata.status === 1 &&
            _idata.data != undefined &&
            _idata.data.length > 0
          ) {
            if (_idata.data[0]) {
              console.log("Domian is::" + _idata.data[0]);
              setW3domain("pagla.eth");
              //setW3domain(_idata.data[0]);
              setW3domains(_idata.data);
            }
          }
        }
      }
    } catch (err: any) {}
  };

  const __metamask = async (_e: any) => {
    const provider = await detectEthereumProvider();

    if (provider) {
      try {
        // Use 'any' type for provider to bypass TypeScript error
        const accounts = await (provider as any).request({
          method: "eth_requestAccounts",
        });
        const chainId = await (provider as any).request({
          method: "eth_chainId",
        });

        if (chainId !== "0x1") {
          setSnackbarMessage("Kindly connect to the Ethereum mainnet!");
          setSnackbarKey((prevKey) => prevKey + 1);
          return;
        }

        setCurrentAddress(accounts[0]);
        setConnectedCrypto("ETH");

        // API call to get domains
        const response = await axios.post(
          "https://urbitgrant.websiteprotector.in/api/w3/eth/get-domain",
          {
            address: accounts[0],
          }
        );

        const data = response.data;
        if (data.status === 1 && data.data && data.data.length > 0) {
          setW3domain(data.data[0]);
        }
      } catch (error) {
        console.error("Error connecting to MetaMask:", error);
        setSnackbarMessage("Error connecting to MetaMask");
        setSnackbarKey((prevKey) => prevKey + 1);
      }
    } else {
      console.error("MetaMask not detected");
      setSnackbarMessage("MetaMask not detected");
      setSnackbarKey((prevKey) => prevKey + 1);
    }
  };

  const __concordium = async (e: any) => {
    try {
      setConnectedWallet(connectedWallet_T);
      setConnectedCrypto("");
      setCurrentAddress("");
      const wallet: any = await connectConcordiumWallet();
      connectedWallet_T.provider = "CCD";
      connectedWallet_T.extenstion = "Concordium Wallet";
      connectedWallet_T.walletAddress = wallet?.account;
      connectedWallet_T.random = Math.random();

      setConnectedWallet(connectedWallet_T);
      setCurrentAddress(connectedWallet_T.walletAddress);
      setConnectedCrypto("CCD");
    } catch (err) {
      console.warn(`failed to connect..`, err);
    }
  };
  const __hashpack = useCallback(
    async (_e: any) => {
      if (isClickDisabled) return;

      setClickDisabled(true);
      console.log(
        "caaaaaaaaaaallllllllllllllllllllllllledddddddddddddddddddddddddddd",
        isClickDisabled
      );
      setConnectedWallet(connectedWallet_T);
      setConnectedCrypto("");
      setCurrentAddress("");
      const wallet = await getHPackProvider();

      // setCurrentAddress();

      fetchHashpackAccount(0);

      setTimeout(() => setClickDisabled(false), 3000);
    },
    [isClickDisabled]
  );
  const fetchHashpackAccount = (_c: any) => {
    if (_c < 10) {
      console.log(" COUNT::" + _c);
      const ct = setTimeout(async () => {
        const _w = await getHashpackWallet();
        console.log("_w==>", _w);
        console.log("_w==>1", _w?.publicKey);

        if (_w?.publicKey) {
          console.log(_w.publicKey);
          connectedWallet_T.provider = "HBAR";
          connectedWallet_T.extenstion = "Hashpack";
          connectedWallet_T.walletAddress = _w.publicKey;
          connectedWallet_T.random = Math.random();
          setConnectedWallet(connectedWallet_T);
          await setCurrentAddress(_w.publicKey);
          setConnectedCrypto("HBAR");
          const _iresponse = await axios.post(
            SDK_API_SERVER + "/api/w3/hbar/get-domain",
            { address: currentAddress }
          );
          if (_iresponse != null && _iresponse.status != undefined) {
            const _idata = _iresponse.data;
            if (
              _idata.status === 1 &&
              _idata.data != undefined &&
              _idata.data.length > 0
            ) {
              if (_idata.data[0]) {
                console.log("Domian is::" + _idata.data[0]);
                setW3domain(_idata.data[0]);
                setW3domains(_idata.data);
              }
            }
          }
        } else {
          // Start next attempt if public key is not available
          fetchHashpackAccount(_c + 1);
          console.log("_w232323");
        }
      }, 2000);
    }
  };
  const getAptosWallet = () => {
    if ("aptos" in window) {
      return window.aptos;
    } else {
      window.open("https://petra.app/", `_blank`);
      return null;
    }
  };
  const __aptos = async (e: any) => {
    setConnectedWallet(connectedWallet_T);
    setConnectedCrypto("");
    setCurrentAddress("");
    const wallet: any = getAptosWallet();
    console.log(wallet);
    if (wallet) {
      try {
        const response = await wallet.connect();
        console.log("Connect");
        console.log(response);
        const account = await wallet.account();
        connectedWallet_T.provider = "APT";
        connectedWallet_T.extenstion = "Petra Aptos Wallet";
        connectedWallet_T.walletAddress = account?.address;
        connectedWallet_T.random = Math.random();

        setConnectedWallet(connectedWallet_T);
        setCurrentAddress(connectedWallet_T.walletAddress);
        setConnectedCrypto("APT");
      } catch (e: any) {
        console.log("Exception:--");
        console.log(e);
      }
    }
  };

  const handleFetch = () => {
    if (currentAddress) {
      const fetchData = () => {
        axios
          .get(
            API_HOST +
              `domain/bname/${connectedCrypto.toLocaleLowerCase()}/address/${currentAddress}`
          )
          .then((response) => {
            if (
              !response?.data[connectedCrypto.toLocaleLowerCase()]?.domains
                .length
            ) {
              setNodomain(true);
            }
            console.log(
              " response?.data[connectedCrypto.toLocaleLowerCase()]?.domains==>",
              !response?.data[connectedCrypto.toLocaleLowerCase()]?.domains
                .length
            );

            setDomains(
              response?.data[connectedCrypto.toLocaleLowerCase()]?.domains
            );
          })
          .catch((error) => {
            // Handle error here
            console.error("Error occurred:", error);
            setSnackbarMessage(error.message);
            setSnackbarKey((prevKey) => prevKey + 1);
          });
      };
      fetchData();
    } else {
      setSnackbarMessage("please connect the wallet first");
      setSnackbarKey((prevKey) => prevKey + 1);
    }
  };

  // const fetchData = () => {
  //   axios
  //     .post(
  //       `https://urbitgrant.websiteprotector.in/api/w3/${connectedCrypto.toLocaleLowerCase()}/get-domain`,
  //       {
  //         address: currentAddress,
  //       }
  //     )
  //     .then((response) => {
  //       // Handle successful response here
  //       console.log("Response data:", response.data);
  //       console.log("Response data:", response.data.data);
  //       setDomains(response.data.data);
  //     })
  //     .catch((error) => {
  //       // Handle error here
  //       console.error("Error occurred:", error);
  //     });
  // };

  // const fetchdata1 = () => {
  //   // const axios = require('axios');
  //   let data = JSON.stringify({
  //     owner: "3xke2Mu4xR65c9DwzQHzbUtWRBVq2qmB9w3tz47h8CQkit4Rtx",
  //   });

  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: "https://api.ccddomain.com/api/ccd-domains",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       console.log("hellqwqq==>", response.data.data[0].domain);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // fetchdata1();

  console.log("connectedCrypto==>", connectedCrypto);

  function handleNext() {
    navigate("/Web3Login", {
      state: {
        id: currentAddress,
        domain: w3domain,
        connectedCrypto: connectedCrypto,
      },
    });
  }

  return (
    <div style={{ marginTop: "30px" }}>
      <CustomSnackbar key={snackbarKey} message={snackbarMessage} />
      <div
        className="col-md-9 mx-auto border rounded-4"
        style={{
          padding: "3rem 2.5rem",
          backgroundColor: "darkkhaki",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          boxShadow: "",
          // width: "100%",
        }}
      >
        <div style={{ fontSize: "40px", fontWeight: "700" }} className="pb-4">
          Connect Wallets
        </div>
        <div
          className="wallet-panel btn-primary d-flex justify-content-center gap-2 mb-3  border rounded-2 p-2"
          style={{ width: "100%" }}
        >
          <div
            className="xborder pointer"
            onClick={(e) => {
              __metamask(e);
              setW3domain("");
              setDomains([]);
              setNodomain(false);
            }}
          >
            <img src="https://lh3.googleusercontent.com/QW0gZ3yugzXDvTANa5-cc1EpabQ2MGnl6enW11O6kIerEaBQGOhgyUOvhRedndD9io8RJMmJZfIXq1rMxUsFHS2Ttw=s60" />
          </div>

          <div
            className="xborder pointer"
            onClick={(e) => {
              __phantom(e);
              setW3domain("");
              setW3domains([]);
              setDomains([]);
              setNodomain(false);
            }}
          >
            <img src="https://lh3.googleusercontent.com/dXvdD2VjLS-imsW8WG2oB3y7sBHhL9gFlv7KZnqZSA9_MU1VROSHRpJidav8-a77uQT1-8X_zK5ibsAC39IFn5tn=s60" />
          </div>
          <div
            className="xborder pointer"
            onClick={(e) => {
              __hashpack(e);
              setW3domain("");
              setW3domains([]);
              setDomains([]);
              setNodomain(false);
            }}
          >
            <img src="https://lh3.googleusercontent.com/11gZzwVrl8X2eoCbag1y5_hhyUqMKxG-zfDThmczUD7TwlX6HS0207EqyKGcz-FY1ZtDrWBwtNIG5VMlp-f6jkniYQ=s60" />
          </div>
          <div
            className="xborder pointer"
            onClick={(e) => {
              __concordium(e);
              setW3domain("");
              setW3domains([]);
              setDomains([]);
              setNodomain(false);
            }}
          >
            <img src="https://lh3.googleusercontent.com/wUkGY6QJsiAQ6G0ZFbUOrs8iRX35V8Wtp0nO3H6YDJEXGWLkkUYiNXDct7ggST54xLCgzm7QlqDCKkMuev99NTKn9pM=s60" />
          </div>
          <div
            className="xborder pointer"
            onClick={(e) => {
              __aptos(e);
              setW3domain("");
              setW3domains([]);
              setDomains([]);
              setNodomain(false);
            }}
          >
            <img src="https://lh3.googleusercontent.com/J_anIZiSwC4cAyEuVYW55I8Xkw5_LJTmv5YBHezvLnlu8Gcdh64pGyOGmEhuqVTREB50qp92btYdbqzPwXk-hsehFww=s60" />
          </div>
        </div>
        <div style={{ display: "flex", paddingBottom: "5px" }}>
          <span className="badge bg-secondary rounded-pill px-4">
            {connectedCrypto}
          </span>
        </div>
        {/* <div className="input-group mb-3">
          <div className="form-floating ">
            <input
              disabled={true}
              type="text"
              className="form-control"
              id="walletAddress"
              placeholder="walletAddress"
              value={currentAddress}
            />
            <label>Selected Account Address</label>
          </div>
        </div> */}
        <div style={{ fontSize: "20px", fontWeight: "700" }}>
          Connected Account Id:-{" "}
          <span style={{ fontSize: "14px" }}>
            {currentAddress ? currentAddress : "Please connect wallet"}
          </span>
        </div>
        <button
          onClick={() => handleFetch()}
          className="btn btn-lg  btn-primary px-4 mt-2 mb-4"
        >
          Fetch Domains
        </button>
        {noDomain && (
          <div style={{ fontSize: "28px", fontWeight: 500 }}>
            No domain to show
          </div>
        )}
        {domains?.[0] && (
          <div style={{ width: "100%" }}>
            <div className="input-group mb-3">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="w3domain"
                  onChange={(e) => {
                    setW3domain(e.target.value);
                  }}
                >
                  {domains &&
                    domains?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
                <label htmlFor="w3domain">Your Web3 Domain(s)</label>
              </div>
            </div>
            <button
              className="btn btn-lg  btn-primary px-4 mt-2 mb-4"
              onClick={() => handleNext()}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Web3Wallet;
