import React, { useCallback,useEffect,useState,useRef,useContext } from 'react'
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import {URLPREFIX,SDK_API_SERVER} from "./constant" ;
const Test=()=>{
    const getI=async()=>{
        //const _iresponse=await axios.post(SDK_API_SERVER+"/api/w3/eth/get-domain",{address:connectedWallet_T.walletAddress});
			const _iresponse=await axios.post(SDK_API_SERVER+"/api/w3/eth/get-domain",{address:'0xD4416b13d2b3a9aBae7AcD5D6C2BbDBE25686401'});
			if(_iresponse!=null && _iresponse.status!=undefined){
					const _idata=_iresponse.data;
					if(_idata.status===1 && _idata.data!=undefined && _idata.data.length>0){
						if(_idata.data[0]){
							console.log("Domian is::"+_idata.data[0]);
							//setW3domain(_idata.data[0]);
						}
					}
			}
    }
	return(
        <>
            <h2>Hello Minni</h2>
            <iframe src="https://hinryt-hocmel.holium.network"
							title="GeeksforGeeks"
							width="450"
							height="550"
			></iframe>
        </>
    )
}

export default Test;
