// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashBoardRow {
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  border-radius: 22px;
}

.dashBoardRow1 {
  background-color: ivory;
}

.dashBoardRow2 {
  background-color: beige;
}

.dashBoardRow3 {
  background-color: palegoldenrod;
}

.dashBoardRow4 {
  background-color: lightyellow;
}

.dashBoardRowbutton {
  font-size: 20px;
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  background-color: #4caf50; /* Green */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dashBoardRowbutton:hover {
  background-color: #45a049;
}

/* .dashBoardRowbutton:active {
  transform: translateY(2px);
} */
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,yBAAyB,EAAE,UAAU;EACrC,YAAY;EACZ,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;GAEG","sourcesContent":[".dashBoardRow {\n  padding: 10px;\n  margin-bottom: 10px;\n  width: 100%;\n  font-weight: 700;\n  font-size: 28px;\n  text-align: center;\n  border-radius: 22px;\n}\n\n.dashBoardRow1 {\n  background-color: ivory;\n}\n\n.dashBoardRow2 {\n  background-color: beige;\n}\n\n.dashBoardRow3 {\n  background-color: palegoldenrod;\n}\n\n.dashBoardRow4 {\n  background-color: lightyellow;\n}\n\n.dashBoardRowbutton {\n  font-size: 20px;\n  padding: 10px 20px;\n  margin-top: 20px;\n  border: none;\n  background-color: #4caf50; /* Green */\n  color: white;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.dashBoardRowbutton:hover {\n  background-color: #45a049;\n}\n\n/* .dashBoardRowbutton:active {\n  transform: translateY(2px);\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
