import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import { useLocation } from 'react-router-dom';
import CustomSnackbar from "./commonComponent/CustomSnackbar/CustomSnackbar";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./App.css";
import "./css/HomePage4.css";
import "./css/Button.css";
import holliumIcon from "./images/hollium.png";

import detectEthereumProvider from "@metamask/detect-provider";
import { URLPREFIX } from "./constant";
import { UserContext, jtoast } from "./context/context";
import { detectConcordiumProvider } from "@concordium/browser-wallet-api-helpers";
import { MetaMaskProvider } from "@metamask/sdk-react";
import { useSDK } from "@metamask/sdk-react";
import { throttle } from "lodash";
import {
  getHPackProvider,
  getHashpackWallet,
  disconnectHashpack,
} from "./hashpack";
import axios from "axios";
import jquery from "jquery";
import { SDK_API_SERVER, API_HOST } from "./constant";
import { getDomainOfEth } from "./domainAddress/W3Domain";
import { log } from "console";
import Storage from "./helperFunctions/Storage";
//import Web3 from 'web3';
const qs = require("qs");

//----ccd
const $: JQueryStatic = jquery;
const MAINNET_GENESIS_BLOCK_HASH =
  "9dd9ca4d19e9393877d2c44b70f89acbfc0883c2243e5eeaecc0d1cd0503f478";
const TESTNET_GENESIS_BLOCK_HASH =
  "4221332d34e1694168c2a0c0b3fd0f273809612cb13d000d5c2e00e85f50f796";

type ItemType = any[];

const urbitLogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="44"
    viewBox="0 0 47 44"
    fill="none"
  >
    <path
      d="M26.5703 32.4536C26.5703 31.4136 25.7272 30.5705 24.6872 30.5705H22.2935C21.2535 30.5705 20.4104 31.4136 20.4104 32.4536L20.4104 41.3707C20.4104 42.4107 21.2535 43.2538 22.2935 43.2538L24.6872 43.2538C25.7272 43.2538 26.5703 42.4107 26.5703 41.3707V32.4536Z"
      fill="black"
    />
    <path
      d="M14.8167 40.4577L10.5955 37.9857C9.66053 37.4382 9.37933 36.2166 9.98081 35.3154L15.949 26.3733C16.2986 25.8496 16.8868 25.5352 17.5165 25.5356L29.5185 25.5436C30.1467 25.5441 30.7334 25.8578 31.0826 26.38L37.0549 35.3118C37.6583 36.2142 37.3759 37.4384 36.4382 37.9852L32.2177 40.4464C31.2934 40.9853 30.1065 40.647 29.6053 39.7017L25.3208 31.6201C24.9942 31.0042 24.3542 30.6191 23.657 30.6191L23.3749 30.6191C22.6769 30.6191 22.0363 31.0051 21.7101 31.6221L17.4331 39.7128C16.932 40.6606 15.7419 40.9995 14.8167 40.4577Z"
      fill="black"
    />
    <path
      d="M32.7607 12.203C31.8935 12.7771 31.6561 13.9456 32.2303 14.8127L33.5518 16.8085C34.126 17.6756 35.2945 17.913 36.1616 17.3388L43.5964 12.4156C44.4635 11.8414 44.701 10.673 44.1268 9.80586L42.8052 7.81009C42.231 6.94297 41.0626 6.70551 40.1955 7.2797L32.7607 12.203Z"
      fill="black"
    />
    <path
      d="M45.939 17.6068L46.2085 22.4912C46.2682 23.573 45.4049 24.482 44.3214 24.478L33.5707 24.4389C32.941 24.4366 32.3541 24.1197 32.0068 23.5945L25.3871 13.5832C25.0405 13.0592 24.9782 12.3968 25.2208 11.8173L29.3705 1.90638C29.7897 0.905121 30.9664 0.464687 31.94 0.944606L36.3223 3.1047C37.2819 3.57772 37.6551 4.75421 37.1437 5.69392L32.7711 13.7282C32.4378 14.3405 32.4701 15.0868 32.855 15.6681L33.0108 15.9033C33.3961 16.4852 34.0717 16.8063 34.7662 16.7376L43.8734 15.8366C44.9403 15.7311 45.8799 16.5363 45.939 17.6068Z"
      fill="black"
    />
    <path
      d="M14.2398 12.203C15.1069 12.7771 15.3444 13.9456 14.7702 14.8127L13.4486 16.8085C12.8744 17.6756 11.706 17.913 10.8389 17.3388L3.40408 12.4156C2.53696 11.8414 2.2995 10.673 2.87369 9.80586L4.19527 7.81009C4.76947 6.94297 5.93789 6.70551 6.805 7.2797L14.2398 12.203Z"
      fill="black"
    />
    <path
      d="M1.0615 17.6068L0.791982 22.4912C0.732287 23.573 1.59559 24.482 2.67906 24.478L13.4298 24.4389C14.0595 24.4366 14.6464 24.1197 14.9937 23.5945L21.6134 13.5832C21.9599 13.0592 22.0223 12.3968 21.7796 11.8173L17.63 1.90638C17.2108 0.905121 16.0341 0.464687 15.0605 0.944606L10.6782 3.1047C9.71858 3.57772 9.34534 4.75421 9.85678 5.69392L14.2294 13.7282C14.5627 14.3405 14.5304 15.0868 14.1455 15.6681L13.9897 15.9033C13.6044 16.4852 12.9288 16.8063 12.2343 16.7376L3.12711 15.8366C2.06019 15.7311 1.12057 16.5363 1.0615 17.6068Z"
      fill="black"
    />
    <path
      d="M27.5098 20.2518C27.5098 22.4662 25.7147 24.2613 23.5003 24.2613C21.2859 24.2613 19.4908 22.4662 19.4908 20.2518C19.4908 18.0374 21.2859 16.2423 23.5003 16.2423C25.7147 16.2423 27.5098 18.0374 27.5098 20.2518Z"
      fill="black"
    />
  </svg>
);

const connectConcordiumWallet = async () => {
  const _provider = await detectConcordiumProvider();
  const _genesisHash = await _provider.getSelectedChain();
  let _account = await _provider.connect();
  _account = await _provider.getMostRecentlySelectedAccount();
  if (_account) {
    return {
      provider: _provider,
      account: _account,
      genesisHash: _genesisHash,
    };
  } else {
    return null;
  }
};

//---------------
const getPhantomProvider = () => {
  if ("phantom" in window) {
    const provider: any = (window as any)?.phantom?.solana;
    console.log(provider);
    if (provider?.isPhantom) return provider;
  }
  window.open("https://phantom.app/", "_blank");
  return null;
};
const connectedWallet_T = {
  walletAddress: "",
  provider: "",
  extenstion: "",
  random: -1,
};

interface DomainMapping {
  domain: string;
  created: string; // or Date if you're using date objects
  // ... any other properties that a domain mapping should have
}

const JApp = () => {
  const navigate = useNavigate();
  const [callFetchData, setCallFetchData]= useState(0);
  const location = useLocation();

  const [xradio, setXRadio] = useState(1);
  const [urbitId, setUrbitId] = useState("");
  const [urbitKey, setUrbitKey] = useState("");
  const [showStore, setShowStore] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");
  const [recipientUrbit, setRecipientUrbit] = useState("");
  const [recipient, setRecipient] = useState("");
  const [ship, setShip] = useState("");
  const [cookie, setCookie] = useState("");
  const [messageContent, setMessageContent] = useState("");
  //Global Wallet Storage
  const [connectedWallet, setConnectedWallet] = useState(connectedWallet_T);
  const [connectedCrypto, setConnectedCrypto] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [w3domain, setW3domain] = useState("");
  const [w3domains, setW3domains] = useState([]);
  const [w2domain, setW2domain] = useState("");
  const [txtRecord, setTxtRecord] = useState("" + Math.random() * 10000000);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [isClickDisabled, setClickDisabled] = useState(false);
  const [domains, setDomains] = useState([]);
  const [noDomain, setNodomain] = useState(false);
  const [apitext, setApitext] = useState("");
  // const [domainsPageData, setDomainPageData] = useState([]);
  const [domainsPageData, setDomainPageData] = useState<DomainMapping[]>([]);
  const [count, setCount] = useState(0);
  //const sleep = (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds));

  const { sdk, connected, connecting, provider, chainId } = useSDK();
  const [connectedPage, setConnectedPage] = useState("/");

  console.log("connectedPage", connectedPage);


  const cookieVal = Storage.get("cookie");
  const urbit = Storage.get("urbitId") || "";

  const apitext1 = Storage.get("text");

  console.log("apitext1==>", apitext1);

  const insertedDomain = Storage.get('insertedDomain')
  useEffect(() => {
    console.dir(window.location.pathname);
    setConnectedPage(window.location.pathname);
  });



  useEffect(() => {
    // Check if the current URL contains "showDomain"
    if (location.pathname.includes("showDomain")) {
      // Set the state, if "showDomain" is present in the URL
      setCallFetchData(callFetchData+1);

    }
  }, [location.pathname]);

  useEffect(() => {
    if (Storage.get("cookie")) {
      setShowStore(true);
    } else setShowStore(false);
  }, [cookieVal]);

  useEffect(() => {
    const fetchData = () => {

      var config = {
        method: "post",
        url: "https://urbitgrant.websiteprotector.in/api/map/get/getAllMaps",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: "urbitId=" + urbit,
      };

      axios
        .request(config)
        .then((response) => {
          // const domainData =
          //   response?.data[connectedCrypto.toLocaleLowerCase()]?.domains;
          // console.log("domainData==>", domainData);

          // setDomainPageData(domainData || []);
          console.log("response in new===>", response?.data?.data);
          setDomainPageData(response?.data?.data || []);
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });
    };

    // Call fetchData when the component mounts or when connectedCrypto or currentAddress changes

    fetchData();

    
  }, [urbitId, count,callFetchData]);

  

  useEffect(() => {
    const fetchData = () => {

      var config = {
        method: "post",
        url: "https://urbitgrant.websiteprotector.in/api/map/get/getAllMaps",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: "urbitId=" + urbit,
      };

      axios
        .request(config)
        .then((response) => {
          // const domainData =
          //   response?.data[connectedCrypto.toLocaleLowerCase()]?.domains;
          // console.log("domainData==>", domainData);

          // setDomainPageData(domainData || []);
          console.log("response in new===>", response?.data?.data);
          setDomainPageData(response?.data?.data || []);
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });
    };

    // Call fetchData when the component mounts or when connectedCrypto or currentAddress changes

    fetchData();
  }, []);

  useEffect(() => {
    // Set the first domain as the default value when w3domains1 is loaded or changed
    if (domains && domains.length > 0) {
      const firstDomain = domains[0];
      setW3domain(firstDomain);
    }
  }, [domains]);

  const convertISOToDateTime = (isoDateString: string): string => {
    const isoDate = new Date(isoDateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "UTC", // Set the time zone to UTC to keep consistency with the provided ISO date
    };
    return isoDate.toLocaleString("en-US", options);
  };

  const __phantom = async (_e: any) => {
    console.log("phantom");

    try {
      console.log("innsidetry phantom");

      setConnectedWallet(connectedWallet_T);
      // setDomains([]);
      setConnectedCrypto("");
      setCurrentAddress("");
      console.log("connectedWallet==>", connectedWallet);
      setConnectedWallet(connectedWallet_T);
      const provider: any = getPhantomProvider();
      if (provider != null) {
        const resp = await provider?.connect();
        connectedWallet_T.provider = "SOL";
        connectedWallet_T.extenstion = "Phantom";
        connectedWallet_T.walletAddress = resp.publicKey.toString();
        connectedWallet_T.random = Math.random();
        setConnectedWallet(connectedWallet_T);
        setCurrentAddress(connectedWallet_T.walletAddress);
        setConnectedCrypto("SOL");
        console.log(resp.publicKey.toString());
        //const _iresponse=await axios.post(SDK_API_SERVER+"/api/w3/sol/get-domain",{address:connectedWallet_T.walletAddress});
        const _iresponse = await axios.post(
          SDK_API_SERVER + "/api/w3/sol/get-domain",
          { address: currentAddress }
        );
        console.log("_iresponse==>", _iresponse);

        if (_iresponse != null && _iresponse.status != undefined) {
          const _idata = _iresponse.data;
          if (
            _idata.status === 1 &&
            _idata.data != undefined &&
            _idata.data.length > 0
          ) {
            if (_idata.data[0]) {
              console.log("Domian is::" + _idata.data[0]);
              //setW3domain(_idata.data[0]);
              setW3domains(_idata.data);
            }
          }
        }
      }
    } catch (err: any) {}
  };

  const __metamask = async (_e: any) => {
    const provider = await detectEthereumProvider();
    // setDomains([]);

    if (provider) {
      try {
        // Use 'any' type for provider to bypass TypeScript error
        setConnectedWallet(connectedWallet_T);
        const accounts = await (provider as any).request({
          method: "eth_requestAccounts",
        });
        const chainId = await (provider as any).request({
          method: "eth_chainId",
        });

        if (chainId !== "0x1") {
          setSnackbarMessage("Kindly connect to the Ethereum mainnet!");
          setSnackbarKey((prevKey) => prevKey + 1);
          return;
        }

        setCurrentAddress(accounts[0]);
        setConnectedCrypto("ETH");
        connectedWallet_T.provider = "ETH";
        connectedWallet_T.extenstion = "Metamask";
        connectedWallet_T.walletAddress = accounts?.[0];
        connectedWallet_T.random = Math.random();
        setConnectedWallet(connectedWallet_T);

        // API call to get domains
        const response = await axios.post(
          "https://urbitgrant.websiteprotector.in/api/w3/eth/get-domain",
          {
            address: accounts[0],
          }
        );

        const data = response.data;
        if (data.status === 1 && data.data && data.data.length > 0) {
          setW3domain(data.data[0]);
        }
      } catch (error) {
        console.error("Error connecting to MetaMask:", error);
        setSnackbarMessage("Error connecting to MetaMask");
        setSnackbarKey((prevKey) => prevKey + 1);
      }
    } else {
      console.error("MetaMask not detected");
      setSnackbarMessage("MetaMask not detected");
      setSnackbarKey((prevKey) => prevKey + 1);
    }
  };

  // const __metamask = async (_e: any) => {
  //   console.log("inside meatmask");

  //   // window.open("https://metamask.io/download.html", "_blank");
  //   try {
  //     //const metamaskApp = useSDK();
  //     setConnectedWallet(connectedWallet_T);
  //     setConnectedCrypto("");
  //     setCurrentAddress("");
  //     //console.log(metamaskApp);
  //     if ((window as any).ethereum) {
  //       try {
  //         // Request account access if needed
  //         let _iresponse = null;
  //         if (connected === false) {
  //           // alert("Please connect Metamask mainnet!");
  //           setSnackbarMessage("Please connect Metamask mainnet!");
  //           setSnackbarKey((prevKey) => prevKey + 1);
  //           return;
  //         }
  //         if (chainId != "0x1") {
  //           // alert("Kindly connect mainnet!");
  //           setSnackbarMessage("Kindly connect mainnet!");
  //           setSnackbarKey((prevKey) => prevKey + 1);
  //           return;
  //         }
  //         const accounts: any = await sdk?.connect();
  //         connectedWallet_T.provider = "ETH";
  //         connectedWallet_T.extenstion = "Metamask";
  //         connectedWallet_T.walletAddress = accounts?.[0];
  //         connectedWallet_T.random = Math.random();
  //         setConnectedWallet(connectedWallet_T);
  //         setCurrentAddress(connectedWallet_T.walletAddress);
  //         setConnectedCrypto("ETH");

  //         // Usage:
  //         //await sleep(3000); // Sleep for 3 seconds
  //         //alert(connectedWallet_T.walletAddress);

  //         let data = JSON.stringify({
  //           address: accounts?.[0],
  //         });

  //         let config = {
  //           method: "post",
  //           maxBodyLength: Infinity,
  //           url: "https://urbitgrant.websiteprotector.in/api/w3/eth/get-domain", //geting domains of etherium(matamask)
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           data: data,
  //         };

  //         axios.request(config).then((response) => {
  //           const _idata = response.data;
  //           console.log(_idata);
  //           if (
  //             _idata.status === 1 &&
  //             _idata.data != undefined &&
  //             _idata.data.length > 0
  //           ) {
  //             if (_idata.data[0]) {
  //               console.log("Domian is::" + _idata.data[0]);
  //               setW3domain(_idata.data[0]);
  //               setW3domains(_idata.data);
  //             }
  //           }
  //           //console.dir(_idata);
  //         });

  //         //const _iresponse=await axios.post(SDK_API_SERVER+"/api/w3/eth/get-domain",{address:connectedWallet_T.walletAddress});
  //       } catch (error) {
  //         console.error("Error connecting to Metamask:", error);
  //       }
  //     } else {
  //       console.error("Metamask not detected");
  //       setSnackbarMessage("Metamask not detected");
  //       setSnackbarKey((prevKey) => prevKey + 1);
  //     }

  //     //const _iresponse=await axios.post(SDK_API_SERVER+"/api/w3/eth/get-domain",{address:connectedWallet_T.walletAddress});
  //   } catch (err: any) {
  //     console.warn(`failed to connect..`, err);
  //   }
  // };

  const __concordium = async (_e: any) => {
    try {
      setConnectedWallet(connectedWallet_T);
      setConnectedCrypto("");
      setCurrentAddress("");
      // setDomains([]);
      const wallet: any = await connectConcordiumWallet();
      connectedWallet_T.provider = "CCD";
      connectedWallet_T.extenstion = "Concordium Wallet";
      connectedWallet_T.walletAddress = wallet?.account;
      connectedWallet_T.random = Math.random();

      setConnectedWallet(connectedWallet_T);
      setCurrentAddress(connectedWallet_T.walletAddress);
      setConnectedCrypto("CCD");
    } catch (err) {
      console.error(`failed to connect..`, err);
    }
  };
  // const __hashpack = async (_e: any) => {
  //   setConnectedWallet(connectedWallet_T);
  //   setConnectedCrypto("");
  //   setCurrentAddress("");
  //   const wallet = await getHPackProvider();
  //   fetchHashpackAccount(0);
  // };
  // const fetchHashpackAccount = (_c: any) => {
  //   if (_c < 10) {
  //     console.log(" COUNT::" + _c);
  //     const ct = setTimeout(async () => {
  //       const _w = getHashpackWallet();
  //       if (_w.publicKey != "") {
  //         console.log(_w.publicKey);
  //         connectedWallet_T.provider = "HBAR";
  //         connectedWallet_T.extenstion = "Hashpack";
  //         connectedWallet_T.walletAddress = _w.publicKey;
  //         connectedWallet_T.random = Math.random();
  //         setConnectedWallet(connectedWallet_T);
  //         await setCurrentAddress(_w.publicKey);
  //         setConnectedCrypto("HBAR");
  //         //disconnectHashpack();
  //         //const _iresponse=await axios.post(SDK_API_SERVER+"/api/w3/hbar/get-domain",{address:connectedWallet_T.walletAddress});
  //         const _iresponse = await axios.post(
  //           SDK_API_SERVER + "/api/w3/hbar/get-domain",
  //           { address: currentAddress }
  //         );
  //         if (_iresponse != null && _iresponse.status != undefined) {
  //           const _idata = _iresponse.data;
  //           if (
  //             _idata.status === 1 &&
  //             _idata.data != undefined &&
  //             _idata.data.length > 0
  //           ) {
  //             if (_idata.data[0]) {
  //               console.log("Domian is::" + _idata.data[0]);
  //               setW3domain(_idata.data[0]);
  //               setW3domains(_idata.data);
  //             }
  //           }
  //         }
  //       } else fetchHashpackAccount(_c + 1);
  //     }, 2000);
  //   }
  // };
  const __hashpack = useCallback(
    async (_e: any) => {
      // setDomains([]);
      if (isClickDisabled) return;

      setClickDisabled(true);
      console.log(
        "caaaaaaaaaaallllllllllllllllllllllllledddddddddddddddddddddddddddd",
        isClickDisabled
      );
      setConnectedWallet(connectedWallet_T);
      setConnectedCrypto("");
      setCurrentAddress("");
      const wallet = await getHPackProvider();

      // setCurrentAddress();

      fetchHashpackAccount(0);

      setTimeout(() => setClickDisabled(false), 3000);
    },
    [isClickDisabled]
  );
  const fetchHashpackAccount = (_c: any) => {
    // setDomains([]);
    if (_c < 10) {
      console.log(" COUNT::" + _c);
      const ct = setTimeout(async () => {
        const _w = await getHashpackWallet();
        console.log("_w==>", _w);
        console.log("_w==>1", _w?.publicKey);

        if (_w?.publicKey) {
          console.log(_w.publicKey);
          connectedWallet_T.provider = "HBAR";
          connectedWallet_T.extenstion = "Hashpack";
          connectedWallet_T.walletAddress = _w.publicKey;
          connectedWallet_T.random = Math.random();
          setConnectedWallet(connectedWallet_T);
          await setCurrentAddress(_w.publicKey);
          setConnectedCrypto("HBAR");
          const _iresponse = await axios.post(
            SDK_API_SERVER + "/api/w3/hbar/get-domain",
            { address: currentAddress }
          );
          if (_iresponse != null && _iresponse.status != undefined) {
            const _idata = _iresponse.data;
            if (
              _idata.status === 1 &&
              _idata.data != undefined &&
              _idata.data.length > 0
            ) {
              if (_idata.data[0]) {
                console.log("Domian is::" + _idata.data[0]);
                setW3domain(_idata.data[0]);
                setW3domains(_idata.data);
              }
            }
          }
        } else {
          // Start next attempt if public key is not available
          fetchHashpackAccount(_c + 1);
          console.log("_w232323");
        }
      }, 2000);
    }
  };

  const getAptosWallet = () => {
    if ("aptos" in window) {
      return window.aptos;
    } else {
      window.open("https://petra.app/", `_blank`);
      return null;
    }
  };
  const __aptos = async (_e: any) => {
    setConnectedWallet(connectedWallet_T);
    // setDomains([]);
    setConnectedCrypto("");
    setCurrentAddress("");
    const wallet: any = getAptosWallet();
    console.log(wallet);
    if (wallet) {
      try {
        const response = await wallet.connect();
        console.log("Connect");
        console.log(response);
        const account = await wallet.account();
        connectedWallet_T.provider = "APT";
        connectedWallet_T.extenstion = "Petra Aptos Wallet";
        connectedWallet_T.walletAddress = account?.address;
        connectedWallet_T.random = Math.random();

        setConnectedWallet(connectedWallet_T);
        setCurrentAddress(connectedWallet_T.walletAddress);
        setConnectedCrypto("APT");
      } catch (e: any) {
        console.log("Exception:--");
        console.error(e);
      }
    }
  };
  console.log("urbitId", urbitId);

  const _chechUrbitLogin = async () => {
    try {
      let errorMessage_ = "";
      if (urbitId === "") {
        errorMessage_ = "Please Enter Urbit Id";
        setErrorMessage(errorMessage_);
        return;
      }
      if (urbitKey === "") {
        errorMessage_ = "Please Enter Urbit Credential";
        setErrorMessage(errorMessage_);
        return;
      }
      var config = {
        method: "post",
        url: SDK_API_SERVER + "/api/urbit-login",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: "id=" + urbitId + "&key=" + urbitKey,
      };
      const response = await axios(config);
      if (response != null && response.status === 200) {
        const data = response.data;
        console.log("response.data===>", response.data);

        console.log("ffsfsfsffsffsffffffffff");
        console.log(data.data);
        setShip(data.data[0].ship);
        setCookie(data.data[0].cookie);
        setApitext(data.data[0].text);
        Storage.set("urbitId", urbitId);
        Storage.set("text", data.data[0].text);
        // setUrbitId(data.data[0].text);

        console.dir(data);
        if (
          data.status === 1 &&
          (data.data[0].status === 204 || data.data[0].status === 200)
        ) {
          return { id: 1, cookie: data.data[0].cookie };
        } else if (data.status === 1 && data.data[0].status === 400) {
          return { id: -1, cookie: "" };
        }
      }
    } catch (error) {
      console.error(error);
    }
    return { id: 0, cookie: "" };
  };
  const __submit1 = async (_e: any) => {
    setLoading(true);
    setErrorMessage("");
    let errorMessage_ = "";
    const iresponse = await _chechUrbitLogin();
    //alert(iresponse);
    console.log("iresponse===>", iresponse?.cookie);
    if (iresponse?.id != 1) {
      // alert("Invalid Urbit Login");
      // lo
      errorMessage_ = "Invalid Urbit Login";
      setErrorMessage(errorMessage_);
      setSnackbarMessage(errorMessage_);
      setSnackbarKey((prevKey) => prevKey + 1);
      setLoading(false);
      return;
    }

    Storage.set("cookie", iresponse?.cookie);
    // console.log("inside message xradio", xradio);

    setSnackbarMessage("you are now logged in");
    setSnackbarKey((prevKey) => prevKey + 1);
    setUrbitKey("");
    if (Storage.get("cookie")) {
      setShowStore(true);
    }
    setLoading(false);
  };

  const __submit = async (_e: any) => {
    setErrorMessage("");
    let errorMessage_ = "";
    // const iresponse = await _chechUrbitLogin();
    // //alert(iresponse);
    // console.log("iresponse===>", iresponse);
    // if (iresponse != 1) {
    //   alert("Invalid Urbit Login");
    //   return;
    // }

    // console.log("inside message xradio", xradio);

    // setShowStore(true);
    // setTimeout(function () {
    //   alert("you are ready to go");
    // }, 1000);
    console.log("w3domain==>", w3domain);

    if (xradio === 1) {
      if (connectedWallet.provider === "") {
        errorMessage_ = "Please Connect Wallet";
        setErrorMessage(errorMessage_);
        return;
      }
      if (connectedWallet.walletAddress === "") {
        errorMessage_ = "Please Select Wallet Address";
        setErrorMessage(errorMessage_);
        return;
      }
      //api start
      if (xradio === 1) {
        // let data1 = qs.stringify({
        //   urbitId: ship,
        //   fullUrl: apitext,
        // });

        let data1 = qs.stringify({
          urbitId: Storage.get('urbitId'),
          fullUrl: Storage.get('text'),
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://urbitgrant.websiteprotector.in/api/map/add/urbit",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: data1,
        };

        axios
          .request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
            Storage.set("domain", domains);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      Storage.set('insertedDomain',w3domain)
      const submitRec = {
        urbitId: urbit,
        addressOf: connectedWallet.provider,
        address: connectedWallet.walletAddress,
        domain: w3domain,
      };
      const url = SDK_API_SERVER + "/api/map/add/w3";
      axios
        .post(url, submitRec)
        .then(async function (response) {
          if (domains && domains.length > 0) {
            navigate("/home/connected");
            setSnackbarMessage("Linking Established");
            setSnackbarKey((prevKey) => prevKey + 1);
          } else {
            setSnackbarMessage("Domain is empty");
            setSnackbarKey((prevKey) => prevKey + 1);
          }
          console.log(response.data);
        })
        .catch(function (error: any) {
          console.log(error);
          return null;
        });
    } else if (xradio === 2) {
      if (w2domain === "") {
        errorMessage_ = "Please enter domain name";
        setErrorMessage(errorMessage_);
        return;
      }
      if (txtRecord === "") {
        errorMessage_ = "Please refresh page for TXT Record";
        setErrorMessage(errorMessage_);
        return;
      }
      const regex =
        /^(?!-)([a-z0-9])+(?!-)([\-\.]{1}[a-z0-9]+)*\.[a-zA-Z]{2,}$/;
      if (regex.test(w2domain)) {
        const submitRec = {
          urbitId: urbitId,
          txtRecord: txtRecord,
          domain: w2domain,
        };
        const url = SDK_API_SERVER + "/api/map/add/w2";

        axios
          .post(url, submitRec)
          .then(async function (response) {
            console.log("data", response.data.status);

            if (response.data.status === 0 || -1) {
              throw new Error("Please try after few seconds");
            }

            const data2 = qs.stringify({
              root: "/mnt/volume_nyc1_02/somkiran",
              index: "index.html login-urbit.html index.nginx-debian.html",
              filename: urbitId,
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: "https://urbitgrant.websiteprotector.in/nginx-config",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              data: data2,
            };

            return axios.request(config);
          })
          .then((response) => {
            console.log(JSON.stringify(response.data));
            setSnackbarMessage("Linking Established");
            setSnackbarKey((prevKey) => prevKey + 1);
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
            setSnackbarMessage(error.message);
            setSnackbarKey((prevKey) => prevKey + 1);
            return null;
          });
      } else {
        errorMessage_ = "Enter valid domain name:" + w2domain;
        setErrorMessage(errorMessage_);
        return;
      }
    }
  };

  console.log("apitext==>", apitext);

  const __sendMessage = async (_e: any) => {
    setErrorMessage1("");
    setErrorMessage("");
    try {
      let errorMessage_ = "";
      if (messageContent === "") {
        setSnackbarMessage("please enter some message");
        setSnackbarKey((prevKey) => prevKey + 1);
        return;
      }
      var config = {
        method: "post",
        url: SDK_API_SERVER + "/api/map/get/domain",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          domain: recipient,
        }),
      };
      const response = await axios(config);
      if (response != null && response.status === 200) {
        const data = response.data;

        if (data.status === 1 && data.data != null) {
          await setRecipientUrbit(data.data.urbitId);

          //const axios = require('axios');
          //const qs = require('qs');
          let data1 = qs.stringify({
            shipFrom: ship,
            shipTo: recipientUrbit,
            cookie: cookie.split(";")[0],
            message: messageContent,
            ownerLoginUrl: apitext,
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: SDK_API_SERVER + "/api/sendMessage",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: data1,
          };

          axios
            .request(config)
            .then((response) => {
              console.log(JSON.stringify(response));
              setSnackbarMessage("message sent successfully");
              setSnackbarKey((prevKey) => prevKey + 1);
            })
            .catch((error) => {
              setSnackbarMessage("there us some error in sending message");
              setSnackbarKey((prevKey) => prevKey + 1);
              console.log(error);
            });
        } else {
          errorMessage_ = "Urbit ID not found with domain";
          setSnackbarMessage(errorMessage_);
          setSnackbarKey((prevKey) => prevKey + 1);
          return;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const getAddress = async () => {
  //   try {
  //     const config = {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     };
  //     const response = await axios.get(
  //       "https://testapi.websiteprotector.in/address/somkiran.eth",
  //       config
  //     );
  //     console.log("Address data:", response.data.address);
  //   } catch (error: any) {
  //     if (error.response) {
  //       console.error("Error fetching data:", error.response.data);
  //       console.error("Status code:", error.response.status);
  //     } else if (error.request) {
  //       console.error("No response received:", error.request);
  //     } else {
  //       console.error("Error setting up the request:", error.message);
  //     }
  //     return null;
  //   }
  // };

  // getAddress();

  const handleFetch = () => {
    const fetchData = () => {
      axios
        .get(
          API_HOST +
            `domain/bname/${connectedCrypto.toLocaleLowerCase()}/address/${currentAddress}`
        )
        .then((response) => {
          if (
            !response?.data[connectedCrypto.toLocaleLowerCase()]?.domains.length
          ) {
            setNodomain(true);
          }
          console.log(
            " response?.data[connectedCrypto.toLocaleLowerCase()]?.domains==>",
            !response?.data[connectedCrypto.toLocaleLowerCase()]?.domains.length
          );

          setDomains(
            response?.data[connectedCrypto.toLocaleLowerCase()]?.domains
          );
        })
        .catch((error) => {
          // Handle error here
          console.error("Error occurred:", error);
          setSnackbarMessage(error.message);
          setSnackbarKey((prevKey) => prevKey + 1);
        });
    };
    fetchData();
  };

  const handleDelete = (domain: string) => {
    console.log(`Delete action for ${domain}`);

    let data = qs.stringify({
      urbitId: domain,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://urbitgrant.websiteprotector.in/api/map/remove/urbit",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setCount((prev) => prev + 1);
        setSnackbarMessage("Successfull");
        setSnackbarKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        console.log(error);
        setSnackbarMessage("error occured");
        setSnackbarKey((prevKey) => prevKey + 1);
      });
  };

  

  function handleShowAll() {
    navigate("/home/showDomain");
  }
  const shouldShowLink = !window.location.pathname.includes("messaging");

  const shouldShowLink2 = !window.location.pathname.includes("showDomain");

  const selectedDomain = Storage.get("domain");

  // console.log("domian page data==>", domainsPageData[0]);
  // Storage.set("currentAddress", currentAddress);

  console.log("apitex1t==>", apitext);

  return (
    <>
      <CustomSnackbar key={snackbarKey} message={snackbarMessage} />
      {!showStore ? (
        <div>
          {loading && (
            <div className="loader-container">
              <div className="loader"></div>
            </div> // This div acts as a loader placeholder
          )}

          {!loading && (
            <div className="container mt-3">
              <div className="row">
                <div
                  className="col-md-9 mx-auto border rounded-4"
                  style={{
                    padding: "3rem 2.5rem",
                    backgroundColor: "darkkhaki",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    boxShadow: "",
                  }}
                >
                  {urbitLogo}
                  <h1 style={{ fontSize: "40px" }}>Login into your Urbit ID</h1>
                  <div className="input-group pt-4 mb-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="urbitId"
                        placeholder="Enter your Urbit Id"
                        onChange={(e) => setUrbitId(e.target.value)}
                      />
                      <label>Enter your Urbit Id</label>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <div className="form-floating ">
                      <input
                        type="Password"
                        className="form-control"
                        id="urbitKey"
                        placeholder="Enter your Credential"
                        onChange={(e) => setUrbitKey(e.target.value)}
                      />
                      <label>Enter your Credential</label>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <button
                      type="submit"
                      style={{ width: "200px" }}
                      className="btn btn-lg  btn-primary px-4"
                      onClick={(e) => __submit1(e)}
                    >
                      Login
                    </button>
                  </div>
                  <a
                    className="pt-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    don't have urbit id?
                  </a>
                </div>
              </div>
            </div>
          )}
          {/* <CustomSnackbar message={snackbarMessage} /> */}
        </div>
      ) : (
        <>
          <div
            className="publicTopBar"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="d-flex justify-content-center">
              <ul className="web-menu">
                <li>
                  <Link to="/home">Home</Link>
                </li>
                <li>
                  {shouldShowLink ? (
                    <Link to="/home/messaging">Messaging</Link>
                  ) : (
                    <Link to="">Messaging</Link>
                  )}
                </li>
                <li>
                  {shouldShowLink2 ? (
                    <Link to="/home/showDomain">Mappings</Link>
                  ) : (
                    <Link to="">Mappings</Link>
                  )}
                </li>
              </ul>
            </div>
            <div>
              <ul
                className="web-menu"
                style={{
                  color: "white",
                  //   paddingRight: "20px",
                  cursor: "pointer",
                }}
              >
                <li>
                  <a
                    onClick={() => {
                      setShowStore(false);
                      setUrbitId("");
                      Storage.remove("cookie");
                      setSnackbarMessage("you are now logged out");
                      navigate("/LoginProvider");
                    }}
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {connectedPage != "/home/messaging" &&
          connectedPage != "/home/showDomain" &&
          connectedPage != "/home/connected" ? (
            <div className="container mt-3" style={{ paddingTop: "3.5rem" }}>
              <div className="row">
                <div
                  className="col-md-9 mx-auto border rounded-4"
                  style={{
                    padding: "3rem 2.5rem",
                    backgroundColor: "darkkhaki",
                  }}
                >
                  <h2 className="text-center" style={{ fontSize: "40px" }}>
                    URBIT Linking
                  </h2>
                  <p style={{ fontWeight: "700" }} className="text-center mb-4">
                    bind you URBIT id to Web2 and Web3 domain
                  </p>
                  <div
                    className="d-flex justify-content-center mb-3"
                    style={{ gap: "8px" }}
                  >
                    <div
                      className={
                        "pointer border rounded-pill px-4 py-1 " +
                        (xradio === 1 ? "bg-warning-x" : "")
                      }
                      style={{
                        fontSize: "0.85rem",
                        width: "35%",
                        height: "44px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={(_e) => setXRadio(1)}
                    >
                      WEB3 Domain
                    </div>
                    <div
                      className={
                        "pointer border rounded-pill px-4 py-1 " +
                        (xradio === 2 ? "bg-warning-x" : "")
                      }
                      style={{
                        fontSize: "0.85rem",
                        width: "35%",
                        height: "44px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={(_e) => setXRadio(2)}
                    >
                      WEB2 Domain
                    </div>
                    {/* <div
                      className={
                        "pointer border rounded-pill px-4 py-1 " +
                        (xradio === 3 ? "bg-warning-x" : "")
                      }
                      style={{ fontSize: "0.85rem" }}
                      onClick={(e) => setXRadio(3)}
                    >
                      Buy Urbit ID
                    </div> */}
                  </div>
                  {xradio === 3 ? (
                    <>
                      <div className="pt-4 mb-3 mt-4">
                        <a
                          className="pointer border rounded-2 px-4 py-4 mb-3 d-block text-decoration-none"
                          href="https://tlon.io/"
                          target="_blank"
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width="36px"
                            height="36px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M23.25 0H0.75C0.335786 0 0 0.335786 0 0.75V5.625C0 6.03921 0.335786 6.375 0.75 6.375H7.5C8.32843 6.375 9 7.04657 9 7.875V11.8125C9 12.5374 8.41237 13.125 7.6875 13.125C6.96263 13.125 6.375 12.5374 6.375 11.8125V9C6.375 8.58579 6.03921 8.25 5.625 8.25H0.75C0.335786 8.25 0 8.58579 0 9V22.5C0 23.3284 0.671573 24 1.5 24H22.5C23.3284 24 24 23.3284 24 22.5V9C24 8.58579 23.6642 8.25 23.25 8.25H18.375C17.9608 8.25 17.625 8.58579 17.625 9V11.8125C17.625 12.5374 17.0374 13.125 16.3125 13.125C15.5876 13.125 15 12.5374 15 11.8125V7.875C15 7.04657 15.6716 6.375 16.5 6.375H23.25C23.6642 6.375 24 6.03921 24 5.625V0.75C24 0.335786 23.6642 0 23.25 0Z"></path>
                          </svg>
                          <span className="mx-2">Tlon</span>
                        </a>
                        <a
                          className="pointer border rounded-2 px-4 py-4 mb-3 d-block text-decoration-none"
                          href="https://planet.one/"
                          target="_blank"
                        >
                          <svg
                            width="36px"
                            height="36px"
                            viewBox="0 0 310 310"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M244.39 25.6669L106.427 0L0 90.0545L5.25013 228.018L118.224 310L253.851 274.268L310 147.727L244.39 25.6669ZM148.292 201.404C175.101 201.404 196.834 180.035 196.834 153.675C196.834 127.315 175.101 105.946 148.292 105.946C121.483 105.946 99.7496 127.315 99.7496 153.675C99.7496 180.035 121.483 201.404 148.292 201.404Z"
                              fill="#C4C4C4"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M244.39 25.6669L106.427 0L0 90.0545L5.25013 228.018L118.224 310L253.851 274.268L310 147.727L244.39 25.6669ZM148.292 201.404C175.101 201.404 196.834 180.035 196.834 153.675C196.834 127.315 175.101 105.946 148.292 105.946C121.483 105.946 99.7496 127.315 99.7496 153.675C99.7496 180.035 121.483 201.404 148.292 201.404Z"
                              fill="url(#paint0_linear_2049_1829)"
                            ></path>
                            <defs>
                              <linearGradient
                                id="paint0_linear_2049_1829"
                                x1="97.2563"
                                y1="-65.2006"
                                x2="229.361"
                                y2="233.134"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#1245FF"></stop>
                                <stop
                                  offset="0.554751"
                                  stop-color="#4E76FF"
                                ></stop>
                                <stop offset="1" stop-color="#C9D8FF"></stop>
                              </linearGradient>
                            </defs>
                          </svg>
                          <span className="mx-2">Planet One</span>
                        </a>
                        <a
                          className="pointer border rounded-2 px-4 py-4 mb-3 d-block text-decoration-none"
                          href="https://hosting.holium.com/"
                          target="_blank"
                        >
                          <img src={holliumIcon} width="36px" height="36px" />
                          <span className="mx-2">Holium</span>
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <>
                        <div className="input-group pt-4 mb-3">
                          <div className="form-floating ">
                            <input
                              type="text"
                              className="form-control"
                              id="urbitId"
                              placeholder="Enter your Urbit Id"
                              onChange={(e) => setUrbitId(e.target.value)}
                            />
                            <label>Enter your Urbit Id</label>
                          </div>
                        </div>
                        <div className="input-group mb-3">
                          <div className="form-floating ">
                            <input
                              type="text"
                              className="form-control"
                              id="urbitKey"
                              placeholder="Enter your Credential"
                              onChange={(e) => setUrbitKey(e.target.value)}
                            />
                            <label>Enter your Credential</label>
                          </div>
                        </div>
                      </> */}
                      {xradio === 1 ? (
                        <>
                          <div className="wallet-panel btn-primary d-flex justify-content-center gap-2 mb-3  border rounded-2 p-2">
                            <div
                              className="xborder pointer"
                              onClick={(e) => {
                                __metamask(e);
                                setW3domain("");
                                setDomains([]);
                                setNodomain(false);
                              }}
                            >
                              <img src="https://lh3.googleusercontent.com/QW0gZ3yugzXDvTANa5-cc1EpabQ2MGnl6enW11O6kIerEaBQGOhgyUOvhRedndD9io8RJMmJZfIXq1rMxUsFHS2Ttw=s60" />
                            </div>

                            <div
                              className="xborder pointer"
                              onClick={(e) => {
                                __phantom(e);
                                setW3domain("");
                                setW3domains([]);
                                setDomains([]);
                                setNodomain(false);
                              }}
                            >
                              <img src="https://lh3.googleusercontent.com/dXvdD2VjLS-imsW8WG2oB3y7sBHhL9gFlv7KZnqZSA9_MU1VROSHRpJidav8-a77uQT1-8X_zK5ibsAC39IFn5tn=s60" />
                            </div>
                            <div
                              className="xborder pointer"
                              onClick={(e) => {
                                __hashpack(e);
                                setW3domain("");
                                setW3domains([]);
                                setDomains([]);
                                setNodomain(false);
                              }}
                            >
                              <img src="https://lh3.googleusercontent.com/11gZzwVrl8X2eoCbag1y5_hhyUqMKxG-zfDThmczUD7TwlX6HS0207EqyKGcz-FY1ZtDrWBwtNIG5VMlp-f6jkniYQ=s60" />
                            </div>
                            <div
                              className="xborder pointer"
                              onClick={(e) => {
                                __concordium(e);
                                setW3domain("");
                                setW3domains([]);
                                setDomains([]);
                                setNodomain(false);
                              }}
                            >
                              <img src="https://lh3.googleusercontent.com/wUkGY6QJsiAQ6G0ZFbUOrs8iRX35V8Wtp0nO3H6YDJEXGWLkkUYiNXDct7ggST54xLCgzm7QlqDCKkMuev99NTKn9pM=s60" />
                            </div>
                            <div
                              className="xborder pointer"
                              onClick={(e) => {
                                __aptos(e);
                                setW3domain("");
                                setW3domains([]);
                                setDomains([]);
                                setNodomain(false);
                              }}
                            >
                              <img src="https://lh3.googleusercontent.com/J_anIZiSwC4cAyEuVYW55I8Xkw5_LJTmv5YBHezvLnlu8Gcdh64pGyOGmEhuqVTREB50qp92btYdbqzPwXk-hsehFww=s60" />
                            </div>
                          </div>
                          <div style={{ display: "flex", marginBottom: "5px" }}>
                            <span className="badge bg-secondary rounded-pill px-4">
                              {connectedCrypto}
                            </span>
                          </div>
                          <div className="input-group mb-3">
                            <div className="form-floating ">
                              <input
                                disabled={true}
                                type="text"
                                className="form-control"
                                id="walletAddress"
                                placeholder="walletAddress"
                                value={currentAddress}
                              />
                              <label>Selected Account Address</label>
                            </div>
                          </div>
                          {currentAddress && (
                            <button
                              onClick={() => handleFetch()}
                              className="btn btn-lg  btn-primary px-4 mt-2 mb-4"
                            >
                              Fetch Domains
                            </button>
                          )}
                          {noDomain && (
                            <div style={{ fontSize: "28px", fontWeight: 500 }}>
                              No domain to show
                            </div>
                          )}
                          {domains?.[0] && (
                            <div style={{ width: "100%" }}>
                              <div className="input-group mb-3">
                                <div className="form-floating">
                                  <select
                                    className="form-select"
                                    id="w3domain"
                                    onChange={(e) => {
                                      setW3domain(e.target.value);
                                      Storage.set("domain", e.target.value);
                                    }}
                                  >
                                    {domains &&
                                      domains?.map((item, index) => (
                                        <option key={index} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                  </select>
                                  <label htmlFor="w3domain">
                                    Your Web3 Domain(s)
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="input-group mb-3">
                            <div className="form-floating ">
                              <input
                                type="text"
                                className="form-control"
                                id="w2domain"
                                onChange={(e) => {
                                  setW2domain(e.target.value);
                                }}
                              />
                              <label>
                                Enter Web2 Domain{" "}
                                <span className="text-muted">
                                  (exmaple.com)
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="input-group mb-3">
                            <div className="form-floating ">
                              <input
                                disabled={true}
                                value={txtRecord}
                                type="text"
                                className="form-control"
                                id="txtRecord"
                              />
                              <label>Copy this and add to DNS TXT Record</label>
                            </div>
                          </div>
                        </>
                      )}

                      <div
                        className="mb-3 text-danger fst-italic"
                        style={{ fontSize: "0.85rem" }}
                      >
                        {errorMessage}
                      </div>
                      {/* {domains && domains.length > 0 && ( */}
                      <button
                        type="submit"
                        className="btn btn-lg  btn-primary px-4"
                        onClick={(e) => __submit(e)}
                      >
                        Submit
                      </button>
                      {/* )} */}

                      {/* <button onClick={() => setShowStore(false)}>
                        {" "}
                        logout
                      </button> */}
                    </>
                  )}
                  <div className="py-4"></div>
                </div>
              </div>
            </div>
          ) : connectedPage === "/home/messaging" ? (
            <div className="container mt-3" style={{ paddingTop: "3.5rem" }}>
              <div className="row">
                <div
                  className="col-md-9 mx-auto border rounded-4"
                  style={{
                    padding: "3rem 2.5rem",
                    backgroundColor: "darkkhaki",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    // width: "100%",
                  }}
                >
                  <h3
                    className="form-floating input-group  mb-3"
                    style={{
                      fontSize: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Send Message Through Muster
                  </h3>
                  <div
                    id="loginContainer"
                    style={{ display: !showStore ? "block" : "none" }}
                  >
                    <div className="input-group pt-4 mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          style={{ width: "560px" }}
                          className="form-control mw-100"
                          id="urbitId"
                          placeholder="Enter your Urbit Id"
                          onChange={(e) => setUrbitId(e.target.value)}
                        />
                        <label>Enter your Urbit Id</label>
                      </div>
                    </div>
                    <div className="input-group mb-3">
                      <div className="form-floating ">
                        <input
                          type="text"
                          className="form-control"
                          id="urbitKey"
                          placeholder="Enter your Credential"
                          onChange={(e) => setUrbitKey(e.target.value)}
                        />
                        <label>Enter your Credential</label>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-lg  btn-primary px-4"
                      onClick={(e) => __submit(e)}
                    >
                      Login
                    </button>
                  </div>
                  <div
                    id="messageContainer"
                    style={{
                      display: showStore ? "block" : "none",
                      width: "100%",
                    }}
                  >
                    <div className="input-group pt-4 mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control mw-100"
                          style={{ width: "100%" }}
                          id="recipient"
                          placeholder="Enter web3/web2 domain to send talk"
                          onChange={(e) => setRecipient(e.target.value)}
                        />
                        <label>Enter web3/web2 domain to send talk</label>
                      </div>
                    </div>
                    <div className="input-group pt-4 mb-3">
                      <div className="form-floating ">
                        <input
                          type="text"
                          className="form-control"
                          id="messageContent"
                          placeholder="Enter your Message Content"
                          onChange={(e) => setMessageContent(e.target.value)}
                        />
                        <label>Enter your Message Content</label>
                      </div>
                    </div>
                    <div
                      className="mb-3 text-danger fst-italic"
                      style={{ fontSize: "0.85rem" }}
                    >
                      {errorMessage1}
                    </div>
                    <button
                      className="btn btn-lg  btn-primary px-4"
                      onClick={(e) => __sendMessage(e)}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : connectedPage === "/home/showDomain" ? (
            <div>
              <div className="container" style={{ marginTop: "80px" }}>
                <div
                  className="col-md-9 mx-auto border rounded-4"
                  style={{
                    padding: "3rem 2.5rem",
                    backgroundColor: "darkkhaki",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    boxShadow: "",
                  }}
                >
                  <div className="header-text">Logged in as {urbit}</div>

                  <div className="table-container mt-2 mb-2">
                    <table className="styled-table">
                      <thead className="thead-dark">
                        <tr>
                          <th>Domain</th>
                          <th>Time</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {domainsPageData?.map((data, index) => (
                          <tr key={index}>
                            <td>{data.domain}</td>
                            <td>{convertISOToDateTime(data.created)}</td>
                            <td>
                              <button className= "btn btn-primary" onClick={() => handleDelete(data.domain)}>
                                Delete
                              </button>
                             
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <button
                    className="btn btn-lg  btn-primary px-4 mt-4"
                    onClick={() => navigate("/home")}
                  >
                    {" "}
                    Go Home
                  </button>
                </div>
              </div>
            </div>
          ) : connectedPage === "/home/connected" ? (
            <div>
              <div>
                <div className=" mt-3" style={{ paddingTop: "3.5rem" }}>
                  <div
                    className="col-md-9 mx-auto border rounded-4"
                    style={{
                      padding: "3rem 2.5rem",
                      backgroundColor: "darkkhaki",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      boxShadow: "",
                      // width: "100%",
                    }}
                  >
                    <h1 style={{ textAlign: "center" }}>
                      Successfully Connected Domain:- {insertedDomain} , with
                      URBIT ID:- {urbit}
                    </h1>
                    <button
                      className="btn btn-lg  btn-primary px-4 mt-4"
                      onClick={handleShowAll}
                    >
                      Show All
                    </button>
                    <button
                      className="btn btn-lg  btn-primary px-4 mt-4"
                      onClick={() => navigate("/home")}
                    >
                      {" "}
                      Go Home
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>hello</div>
          )}
        </>
      )}
    </>
  );
};

export default JApp;
