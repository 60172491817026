import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_HOST } from "../constant";
import axios from "axios";
import Storage from "../helperFunctions/Storage";
import "./TableComponent.css";
import CustomSnackbar from "../commonComponent/CustomSnackbar/CustomSnackbar";

const qs = require("qs");

interface DomainMapping {
  domain: string;
  created: string; // or Date if you're using date objects
  // ... any other properties that a domain mapping should have
}

const TableComponent: React.FC = () => {
  const [domains, setDomains] = useState<DomainMapping[]>([]);
  const [count, setCount] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKey, setSnackbarKey] = useState(0);

  const navigate = useNavigate();

  const location = useLocation();
  const { id, domain, connectedCrypto, urbitId, text } = location.state || {};

  console.log("text==>", text);

  useEffect(() => {
    const fetchData = () => {
      console.log("bhaga");

      var config = {
        method: "post",
        url: "https://urbitgrant.websiteprotector.in/api/map/get/getAllMaps",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: "urbitId=" + urbitId,
      };

      axios
        .request(config)
        .then((response) => {
          // const domainData =
          //   response?.data[connectedCrypto.toLocaleLowerCase()]?.domains;
          // console.log("domainData==>", domainData);

          // setDomainPageData(domainData || []);
          console.log("response in new===>", response?.data?.data);
          setDomains(response?.data?.data || []);
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });
    };

    // Call fetchData when the component mounts or when connectedCrypto or currentAddress changes

    fetchData();
  }, [urbitId, count]); // Dependencies array

  const handleDelete = (domain: string) => {
    console.log(`Delete action for ${domain}`);

    let data = qs.stringify({
      urbitId: urbitId,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://urbitgrant.websiteprotector.in/api/map/remove/urbit",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setCount((prev) => prev + 1);
        setSnackbarMessage("Successfull");
        setSnackbarKey((prevKey) => prevKey + 1);
      })
      .catch((error) => {
        setSnackbarMessage("Error occured");
        setSnackbarKey((prevKey) => prevKey + 1);
        console.log(error);
      });
  };

  const convertISOToDateTime = (isoDateString: string): string => {
    const isoDate = new Date(isoDateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "UTC", // Set the time zone to UTC to keep consistency with the provided ISO date
    };
    return isoDate.toLocaleString("en-US", options);
  };

  function handleLogOut() {
    Storage.remove("cookie");
    navigate("/LoginProvider");
  }

  const shouldShowLink = !window.location.pathname.includes("messaging");

  const shouldShowLink2 = !window.location.pathname.includes("showDomain");

  return (
    <div>
      <CustomSnackbar key={snackbarKey} message={snackbarMessage} />
      <div
        className="publicTopBar"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="d-flex justify-content-center">
          <ul className="web-menu">
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              {shouldShowLink ? (
                <Link to="/home/messaging">Messaging</Link>
              ) : (
                <Link to="">Messaging</Link>
              )}
            </li>
            <li>
              {shouldShowLink2 ? (
                <Link to="/home/showDomain">Mapping</Link>
              ) : (
                <Link to="">domains</Link>
              )}
            </li>
          </ul>
        </div>
        <div>
          <ul
            className="web-menu"
            style={{
              color: "white",
              //   paddingRight: "20px",
              cursor: "pointer",
            }}
          >
            <li>
              <a
                onClick={() => {
                  Storage.remove("cookie");
                  navigate("/LoginProvider");
                }}
              >
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="container" style={{ marginTop: "80px" }}>
        <div
          className="col-md-9 mx-auto border rounded-4"
          style={{
            padding: "3rem 2.5rem",
            backgroundColor: "darkkhaki",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            boxShadow: "",
          }}
        >
          <div className="header-text">Logged in as {urbitId}</div>
          <div className="table-container">
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Domain</th>
                  <th>Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {domains?.map((data, index) => (
                  <tr key={index}>
                    <td>{data.domain}</td>
                    <td>{convertISOToDateTime(data.created)}</td>
                    <td>
                      <button onClick={() => handleDelete(data.domain)}>
                        Delete
                      </button>
                      
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            className="btn btn-lg  btn-primary px-4 mt-4"
            onClick={() => navigate("/home")}
          >
            {" "}
            Go Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableComponent;
