import React from "react";
import holliumIcon from "./images/hollium.png";

const Signup = () => {
  return (
    <>
      <div className="container mt-3" style={{ paddingTop: "3.5rem" }}>
        <div className="row">
          <div
            className="col-md-9 mx-auto border rounded-4"
            style={{
              padding: "3rem 2.5rem",
              backgroundColor: "darkkhaki",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h1 style={{ fontSize: "40px" }}>Choose a provider</h1>
            <div style={{ width: "100%" }} className="pt-4 mb-3 mt-4">
              <a
                className="pointer border rounded-2 px-4 py-4 mb-3 d-block text-decoration-none"
                href="https://tlon.io/"
                target="_blank"
              >
                <svg
                  viewBox="0 0 24 24"
                  width="36px"
                  height="36px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M23.25 0H0.75C0.335786 0 0 0.335786 0 0.75V5.625C0 6.03921 0.335786 6.375 0.75 6.375H7.5C8.32843 6.375 9 7.04657 9 7.875V11.8125C9 12.5374 8.41237 13.125 7.6875 13.125C6.96263 13.125 6.375 12.5374 6.375 11.8125V9C6.375 8.58579 6.03921 8.25 5.625 8.25H0.75C0.335786 8.25 0 8.58579 0 9V22.5C0 23.3284 0.671573 24 1.5 24H22.5C23.3284 24 24 23.3284 24 22.5V9C24 8.58579 23.6642 8.25 23.25 8.25H18.375C17.9608 8.25 17.625 8.58579 17.625 9V11.8125C17.625 12.5374 17.0374 13.125 16.3125 13.125C15.5876 13.125 15 12.5374 15 11.8125V7.875C15 7.04657 15.6716 6.375 16.5 6.375H23.25C23.6642 6.375 24 6.03921 24 5.625V0.75C24 0.335786 23.6642 0 23.25 0Z"></path>
                </svg>
                <span className="mx-2">Tlon</span>
              </a>
              <a
                className="pointer border rounded-2 px-4 py-4 mb-3 d-block text-decoration-none"
                href="https://planet.one/"
                target="_blank"
              >
                <svg
                  width="36px"
                  height="36px"
                  viewBox="0 0 310 310"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M244.39 25.6669L106.427 0L0 90.0545L5.25013 228.018L118.224 310L253.851 274.268L310 147.727L244.39 25.6669ZM148.292 201.404C175.101 201.404 196.834 180.035 196.834 153.675C196.834 127.315 175.101 105.946 148.292 105.946C121.483 105.946 99.7496 127.315 99.7496 153.675C99.7496 180.035 121.483 201.404 148.292 201.404Z"
                    fill="#C4C4C4"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M244.39 25.6669L106.427 0L0 90.0545L5.25013 228.018L118.224 310L253.851 274.268L310 147.727L244.39 25.6669ZM148.292 201.404C175.101 201.404 196.834 180.035 196.834 153.675C196.834 127.315 175.101 105.946 148.292 105.946C121.483 105.946 99.7496 127.315 99.7496 153.675C99.7496 180.035 121.483 201.404 148.292 201.404Z"
                    fill="url(#paint0_linear_2049_1829)"
                  ></path>
                  <defs>
                    <linearGradient
                      id="paint0_linear_2049_1829"
                      x1="97.2563"
                      y1="-65.2006"
                      x2="229.361"
                      y2="233.134"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#1245FF"></stop>
                      <stop offset="0.554751" stop-color="#4E76FF"></stop>
                      <stop offset="1" stop-color="#C9D8FF"></stop>
                    </linearGradient>
                  </defs>
                </svg>
                <span className="mx-2">Planet One</span>
              </a>
              <a
                className="pointer border rounded-2 px-4 py-4 mb-3 d-block text-decoration-none"
                href="https://hosting.holium.com/"
                target="_blank"
              >
                <img src={holliumIcon} width="36px" height="36px" />
                <span className="mx-2">Holium</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
