import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Storage from "../../helperFunctions/Storage";

const SuccessfullyConnected = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, domain, connectedCrypto, urbitId, text } = location.state || {};
  
  const insertedDomain = Storage.get('insertedDomain')
  console.log("inside success fully", id, domain, connectedCrypto, text);
  // function handleShowAll() {
  //   navigate("/table", {
  //     state: {
  //       id: id,
  //       domain: domain,
  //       connectedCrypto: connectedCrypto,
  //       urbitId: urbitId,
  //       text: text,
  //     },
  //   });
  // }
  function handleShowAll() {
    navigate("/home/showDomain");
  }
  // function handleLogOut() {
  //   Storage.sremove("cookie");
  //   navigate("/LoginProvider");
  // }

  const shouldShowLink = !window.location.pathname.includes("messaging");

  const shouldShowLink2 = !window.location.pathname.includes("showDomain");
  return (
    <div>
      <div
        className="publicTopBar"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="d-flex justify-content-center">
          <ul className="web-menu">
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              {shouldShowLink ? (
                <Link to="/home/messaging">Messaging</Link>
              ) : (
                <Link to="">Messaging</Link>
              )}
            </li>
            <li>
              {shouldShowLink2 ? (
                <Link to="/home/showDomain">Mapping</Link>
              ) : (
                <Link to="">domains</Link>
              )}
            </li>
          </ul>
        </div>
        <div>
          <ul
            className="web-menu"
            style={{
              color: "white",
              //   paddingRight: "20px",
              cursor: "pointer",
            }}
          >
            <li>
              <a
                onClick={() => {
                  Storage.remove("cookie");
                  navigate("/LoginProvider");
                }}
              >
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className=" mt-3" style={{ paddingTop: "3.5rem" }}>
        <div
          className="col-md-9 mx-auto border rounded-4"
          style={{
            padding: "3rem 2.5rem",
            backgroundColor: "darkkhaki",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            boxShadow: "",
            // width: "100%",
          }}
        >
          <h1 style={{ textAlign: "center" }}>
            Successfully Connected Domain:- {insertedDomain} , with URBIT ID:- {urbitId}
          </h1>
          <button
            className="btn btn-lg  btn-primary px-4 mt-4"
            onClick={handleShowAll}
          >
            Show All
          </button>
          <button
            className="btn btn-lg  btn-primary px-4 mt-4"
            onClick={() => navigate("/home")}
          >
            {" "}
            Go Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessfullyConnected;
