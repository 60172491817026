import React from "react";
import { useLocation } from "react-router-dom";
import Image from "../../images/thumb.png";
import boat from "../../images/boat.png";

const ThumbsUp = () => {
  const { state } = useLocation();
  console.log("state==>", state);
  return (
    <div>
      <div
        className="container "
        style={{ marginTop: "30px", textAlign: "left" }}
      >
        <div className="row">
          <div
            className="col-md-8 mx-auto border rounded-4"
            style={{
              padding: "3rem 2.5rem",
              //   backgroundColor: "darkkhaki",
              display: "flex",
              justifyContent: "center",
              //   alignItems: "left",
              flexDirection: "column",
              boxShadow: "",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingBottom: "50px",
              }}
            >
              <img width={140} height={140} src={Image} alt="thumbsup" />
            </div>
            <div style={{ fontSize: "24px", fontWeight: 700 }}>
              Cookie:{" "}
              <span style={{ fontSize: "17px", fontWeight: 500 }}>
                {state.cookie}
              </span>
            </div>
            <div style={{ fontSize: "24px", fontWeight: 700 }}>
              <img width={40} height={40} src={boat} alt="boat" />
              {": "}
              <span style={{ fontSize: "17px", fontWeight: 500 }}>
                {state.ship}
              </span>
            </div>
            <div style={{ fontSize: "24px", fontWeight: 700 }}>
              Status:{" "}
              <span style={{ fontSize: "17px", fontWeight: 500 }}>
                {state.status}
              </span>
            </div>
            <div style={{ fontSize: "24px", fontWeight: 700 }}>
              statusText :{" "}
              <span style={{ fontSize: "17px", fontWeight: 500 }}>
                {state.statusText}
              </span>
            </div>
            <div style={{ fontSize: "24px", fontWeight: 700 }}>
              URL :{" "}
              <span style={{ fontSize: "17px", fontWeight: 500 }}>
                {state.text}
              </span>
            </div>
          </div>
        </div>
      </div>
      ;
    </div>
  );
};

export default ThumbsUp;
