import { stat } from "fs";
import { HashConnect, HashConnectTypes, MessageTypes } from "hashconnect";
import { AccountId } from "@hashgraph/sdk";
import { WEB3_SERVER_TYPE } from "./constant";
const appMetadata: HashConnectTypes.AppMetadata = {
  name: "Web23",
  description: "Smart domains. Tokenized on Hedera.",
  icon: "https://www.web23.io/new/Web23_Logo-Horizontal_White.svg",
};

let saveData: any = {
  topic: "",
  pairingString: "",
  privateKey: "",
  pairedWalletData: null,
  pairedAccounts: [],
};
let hashpackWallet: any = {
  wallet: null,
  publicKey: null,
  status: null,
  ext: false,
  walletConnection: null,
  balance: "-",
  earlyAccess: false,
};
const hashconnect = new HashConnect();
hashconnect.pairingEvent.on((data: any) => {
  if (Object.keys(saveData).length === 0 || !saveData.pairedWalletData) {
    saveData.pairedWalletData = data.metadata;
    saveData.pairedAccounts = data.accountIds; // Assuming you want to replace the array
  }
  console.log("-------Pairing Events::");
  console.log(data);
  data.accountIds.forEach((id: AccountId) => {
    if (!saveData.pairedAccounts.includes(id)) {
      saveData.pairedAccounts.push(id);
    }
    hashpackWallet.publicKey = id;
    hashpackWallet.status = "paired";
    hashpackWallet.walletConnection = saveData;
  });
});
hashconnect.foundExtensionEvent.on((data) => {
  //console.log("Extension Found");
  hashpackWallet.ext = true;
});
hashconnect.connectionStatusChangeEvent.once((_status: any) => {
  console.log("STATUS CHANGED ");
  console.log("-ss::" + _status); // Connected returned
});
export const getHPackProvider = async () => {
  try {
    let loadLocalData = false;

    let foundData = hashpackWallet.walletConnection;

    console.log("foundData==>", foundData);

    if (foundData) {
      saveData = foundData;
      hashpackWallet.publicKey = saveData.pairedAccounts[0];
      loadLocalData = true;
    }
    if (!loadLocalData) {
      console.log(" FIrst Time");
      let initData = await hashconnect.init(
        appMetadata,
        WEB3_SERVER_TYPE,
        false
      );
      const state = await hashconnect.connect();
      console.log("TOP::" + initData.topic);
      console.log("TOP::" + state);
      console.log(initData);

      saveData.topic = initData.topic;
      saveData.privateKey = initData.encryptionKey;
      saveData.pairingString = hashconnect.generatePairingString(
        saveData.topic,
        WEB3_SERVER_TYPE,
        true
      );
      //find any supported local wallets
      hashconnect.findLocalWallets();
      //connected
      await hashconnect.init(appMetadata, saveData.privateKey);
      hashconnect.connectToLocalWallet();
      return saveData;
    } else {
      if (hashpackWallet.status === "paired") {
        console.log("Already Paired");
        return saveData;
      } else {
        await hashconnect.init(appMetadata, saveData.privateKey);
        hashconnect.connectToLocalWallet();
        //paired
        return saveData;
      }
    }
  } catch (error) {
    console.error(error);
  }
};
export const getHashpackWallet = () => {
  return hashpackWallet;
};
export const disconnectHashpack = () => {
  hashconnect.disconnect(hashpackWallet.topic);
  hashpackWallet.wallet = null;
  hashpackWallet.publicKey = null;
  hashpackWallet.status = null;
  hashpackWallet.ext = false;
  hashpackWallet.walletConnection = null;
  hashpackWallet.balance = "-";
  hashpackWallet.earlyAccess = false;
};
