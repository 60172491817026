import React from "react";
import { Route, Navigate } from "react-router-dom";
import { URLPREFIX } from "../constant";
import Storage from "./Storage";

export const PrivateRoute = ({ children }) => {
  const authValue = Storage.get("cookie"); // Replace 'yourSessionValue' with the key you use
  console.log("authValue==>", authValue);

  return authValue ? (
    children
  ) : (
    <Navigate to={URLPREFIX + "/LoginProvider"} replace />
  );
};
